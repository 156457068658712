<template>
  <AContainer class="b-about-amgen is-fluid is-paddingless mb-6 p-3">
    <ASectionTitle>
      <template v-slot:icon>
        <img src="../../assets/info_v2.svg" alt="Ícone sobre a Amgen" />
      </template>
      <template v-slot:title>
        <span style="color: #70CDED !important;">
          Sobre a Amgen
        </span>
      </template>
    </ASectionTitle>
    <div
      class="o-about has-text-centered mt-5 pb-6 pl-4 pr-4 pt-5"
      style=" background: rgb(92, 86, 162)!important"
    >
      <ALogoAmgen class="mb-4 is-hidden-tablet" black />
      <ALogoAmgen class="mb-4 is-hidden-mobile" white />
      <MVideo class="is-inline-block">
        <template v-slot:playButton>
          <img
            class="play"
            src="../../assets/play-button.svg"
            alt="Ícone Play"
          />
        </template>
        <source
          src="../../assets/amgen-a-procura-das-respostas-escondidas.mp4"
          preload="none"
          type="video/mp4"
        />
      </MVideo>
      <AContent class="has-text-left mt-6">
        <p style="text-align: center;">
          A Amgen<sup>®</sup> é uma das líderes mundiais em biotecnologia e
          baseia sua atuação em valores profundamente enraizados na ciência e na
          inovação. Utilizamos ciência e tecnologia de ponta para estudar os
          mais sutis mecanismos biológicos em busca de
          <strong
            >terapias que melhorem a vida de quem sofre com doenças
            graves</strong
          >.
        </p>
        <p style="text-align: center;">
          Temos orgulho do que conquistamos e sabemos que o envelhecimento da
          população global exigirá novos tratamentos para doenças como câncer e
          doenças cardíacas, que ceifam milhões de vidas e custam à sociedade
          centenas de bilhões de reais a cada ano<sup>1</sup>. O espírito
          pioneiro da Amgen<sup>®</sup>, uma marca registrada por quatro
          décadas, nos dá a confiança de que podemos desempenhar um papel cada
          vez mais importante no ecossistema global de saúde e populacional.
        </p>
      </AContent>
    </div>
    <AColumns class="c-references-container is-centered is-gapless is-mobile">
      <AColumn class="is-full">
        <MReferences>
          <li style="color: #d1d1d1;">
            Sobre Nós. Amgen. Disponível em:
            <a
              href="https://www.amgen.com.br/about"
              rel="noopener noreferrer"
              target="_blank"
              style="color: #d1d1d1; font-weight: 800;"
              >https://www.amgen.com.br/about</a
            >. Acessado em: 29 de maio de 2023.
          </li>
        </MReferences>
      </AColumn>
    </AColumns>
  </AContainer>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import AContainer from "@/components/atoms/Container.vue";
import AContent from "@/components/atoms/Content.vue";
import ALogoAmgen from "@/components/atoms/LogoAmgen.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import MReferences from "@/components/molecules/References.vue";
import MVideo from "@/components/molecules/Video.vue";

export default {
  components: {
    AColumn,
    AColumns,
    AContainer,
    AContent,
    ASectionTitle,
    ALogoAmgen,
    MReferences,
    MVideo
  },
  name: "OAboutAmgen"
};
</script>

<style lang="scss">
.b-about-amgen {
  .o-about {
    background-color: $white;
    color: $lochmara;
  }

  .a-logo-amgen {
    width: 147px;
  }

  .c-references-container {
    margin: 0 auto;
    max-width: 80%;
  }
}

@include media(">=tablet") {
  .b-about-amgen {
    .o-about {
      background-color: initial;
      color: $white;
    }
  }
}

.t-about .o-about {
  background-color: #5c56a2 !important;
  border: 2px solid #ffffff40;
  border-radius: 10px;
}
</style>
